import jwt from 'jwt-decode';
import SvgColor from '../../../components/svg-color';

const token = localStorage.getItem('token') ? jwt(localStorage.getItem('token')) : {};
const { role } = token;
console.log(role);

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'Дашбоард',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Хэрэглэгч',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Байгууллага',
    path: '/dashboard/company',
    icon: icon('ic_user'),
  },
  {
    title: 'Зөвлөхүүд',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'Мэдээ мэдээлэл',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];  

// const navUserConfig = [
//   {
//     title: 'Мэдээ мэдээлэл',
//     path: '/dashboard/products',
//     icon: icon('ic_cart'),
//   },
// ];

// export default role === 1 ? navConfig : navUserConfig;
export default navConfig;
