import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import CompanyPage from './pages/company/CompanyPage';
import AddCompanyPage from './pages/company/AddCompanyPage';
import AddNewsPage from './pages/news/AddNewsPage';
import AddConsultant from './pages/consultant/AddConsultant';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/blog" />, index: true },
        // { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'company', element: <CompanyPage /> },
        { path: 'company/add/:lang', element: <AddCompanyPage /> },
        { path: 'company/detail/:lang/:id', element: <AddCompanyPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'consultant/add/:lang', element: <AddConsultant /> },
        { path: 'consultant/detail/:id', element: <AddConsultant /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'blog/:lang/add', element: <AddNewsPage /> },
        { path: 'blog/:lang/details/:id', element: <AddNewsPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
