import { useState } from 'react';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField, Divider, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { signin } from '../../../api/main';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from 'src/components/hook-form';

const LoginForm = () => {
  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Буруу и-мэйл байна")
      .required("И-мэйлээ оруулна уу"),
    password: Yup.string().required("Нууц үгээ оруулна уу"),
  });

  const defaultValues = {
    email: "",
    password: ""
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    signin(data)
      .then((res) => {
        if (res.success) {
          const { token } = res;
          localStorage.removeItem('token');
          localStorage.setItem('token', token);
          enqueueSnackbar('Амжилттай', { variant: 'success' });
          navigate('/dashboard', { replace: true });
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar('Алдаа гарлаа', { variant: 'error' });
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="email"
          label="Майл хаяг"
        />

        <RHFTextField
          name="password"
          label="Нууц үг"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
      {isError && <Alert severity="error" style={{ marginTop: 20 }}>Нэвтрэх нэр эсвэл нууц үг буруу байна.</Alert>}

      <Divider sx={{ my: 3 }} />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Нэвтрэх
      </LoadingButton>
      {/* <LoadingButton fullWidth size="large" type="submit" variant="contained">
        Нэвтрэх
      </LoadingButton> */}
    </FormProvider>
  );
}

export default LoginForm;