import { useState, useEffect } from 'react';
import { Button, Container, Stack, Typography, Box, TextField, CircularProgress, Snackbar } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadImage, addConsultant, getConsultantDetail, putConsultant } from '../../api/main';

const AddConsultant = () => {
  const navigate = useNavigate();
  const { id, lang } = useParams();

  const [image, setImage] = useState();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      console.log(id);
      getConsultantDetail(id).then(res => {
        setData(res.data[0]);
      })
    }
  }, [id]);

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    uploadImage(formData).then(res => {
      setData({ ...data, [event.target.name]: res.path });
      setImage(res.path);
    }).catch(error => {
      console.error(error);
    });
  };

  const handleAdd = () => {
    setLoading(true);
    if (id) {
      putConsultant(id, data).then(res => {
        setLoading(false);
        if (res.success) {
          alert('Амжилттай')
        }
        navigate('/dashboard/products');
      });
    } else {
      addConsultant(lang, data).then(res => {
        setLoading(false);
        if (res.success) {
          alert('Амжилттай');
        }
        navigate('/dashboard/products');
      });
    }
  }

  return (
    <Container>
      <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
        <Box>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            <Typography id='model-modal-title' variant="h6" m={3} component="h2">
              Зөвлөх нэмэх
            </Typography>
            <TextField
              name="display_name"
              label="Харагдах нэр"
              value={data?.display_name || ""}
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              required
            />
            <TextField
              name="first_name"
              label="Нэр"
              value={data?.first_name || ""}
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              required
            />
            <TextField
              name="last_name"
              label="Овог"
              value={data?.last_name || ""}
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              required
            />
            <TextField
              name="title"
              label="Ажлын тодорхойлолт"
              value={data?.title || ""}
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              required
            />
            {image ? <img src={image} width={300} /> : (
              <Button
                variant="contained"
                component="label"
                style={{ marginBottom: 20 }}
              >
                Зураг оруулах
                <input type='file' name='image' onChange={handleFileUpload} hidden />
              </Button>
            )}
            <Button onClick={handleAdd} variant="contained">{id ? 'Засах' : 'Нэмэх'} </Button>
            {loading && <CircularProgress color="inherit" />}
          </div>
        </Box>
      </Stack>
    </Container>
  );
}

export default AddConsultant;