import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Stack, Button, MenuItem, Container, Typography, Select } from '@mui/material';
import CustomTable from "../../components/CustomTable";
import Iconify from '../../components/iconify';
import { getCompany, deleteCompany } from '../../api/main';
import Image from '../../components/Image';
import { useNavigate } from 'react-router-dom';

const columns = [
  {
    field: 'logo_url',
    headerName: 'Зураг',
    align: 'center',
    renderCell: (params) => <Image sx={{ width: 80, height: 80 }} src={params.value || ''} />,
    width: 50,
  },
  {
    field: 'name',
    headerName: 'Нэр',
    width: 100,
  },
  {
    field: 'description',
    headerName: 'Дэлгэрэнгүй',
    width: 300,
  },
  {
    field: 'edit',
    headerName: 'Засах',
    width: 50,
    align: 'left'
  },
  {
    field: 'delete',
    headerName: 'Устгах',
    width: 50,
  },
];

const CompanyPage = () => {
  const navigate = useNavigate();

  const [language, setLanguage] = useState('mn');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCompany(language).then(res => {
      setData(res.data);
      setLoading(false);
    })
  }, [language]);

  const handleClick = () => {
    navigate(`/dashboard/company/add/${language}`);
  };

  const edit = id => {
    navigate(`/dashboard/company/detail/${language}/${id}`);
  }

  return (
    <>
      <Helmet>
        <title> Байгууллагын жагсаалт </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Байгууллага
          </Typography>
          <div style={{ display: 'flex' }}>
            <Select name='language'
              defaultValue={language}
              onChange={(e) => setLanguage(e.target.value)}
              sx={{ height: 40, mr: 2, alignSelf: 'center' }}
            >
              <MenuItem value='mn'><em>MN</em></MenuItem>
              <MenuItem value='en'><em>EN</em></MenuItem>
              <MenuItem value='cn'><em>CN</em></MenuItem>
            </Select>
            <Button variant="contained" onClick={handleClick} startIcon={<Iconify icon="eva:plus-fill" />}>
              Нэмэх
            </Button>
          </div>
        </Stack>

        <Card>
          <CustomTable rows={data} columns={columns} edit={edit} remove={deleteCompany} loading={loading} />
        </Card>
      </Container>
    </>
  );
}

export default CompanyPage;