import axios from "axios";

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

const whitelist = ["/api/signin"];

axiosInstance.interceptors.request.use(
  (config) => {
    const currentPath = config.url;
    if (currentPath && whitelist.find((path) => currentPath.includes(path)))
      return config;

    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("🚀 ~ error:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject((error.response && error.response.data) || "Алдаа гарлаа.");
  }
);

export default axiosInstance;
