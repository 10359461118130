import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Container, Stack, Typography, Modal, Box, TextField, Select, MenuItem, CircularProgress } from '@mui/material';
import Iconify from '../components/iconify';
// import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// import POSTS from '../_mock/blog';
import { getCompany, postNews, getNews } from '../api/main';
import { AppNewsUpdate } from '../sections/@dashboard/app';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

// const SORT_OPTIONS = [
//   { value: 'latest', label: 'Latest' },
//   { value: 'popular', label: 'Popular' },
//   { value: 'oldest', label: 'Oldest' },
// ];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  border: '10px',
  borderRadius: '15px',
  bgcolor: 'background.paper',
  p: 4,
};


export default function BlogPage() {
  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [org, setOrg] = useState(1);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(state?.lang || 'mn');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchNews = () => {
    getNews(language).then(res => {
      setPosts(res.data);
    });
  }

  useEffect(() => {
    fetchNews();
    // getCompany().then(res => {
    //   setCompany(res.data);
    // });
  }, [language]);

  const handleAdd = (event) => {
    event.preventDefault();
    const news = { imageUrl, title, desc: description, organ_id: org }
    postNews(news).then(res => {
      setLoading(true);
      if (res.success) {
        enqueueSnackbar('Амжилттай', { variant: 'success' });
        handleClose();
      } else {
        enqueueSnackbar('Алдаа гарлаа', { variant: 'error' });
      }
      setLoading(false);
    });
  }

  return (
    <>
      <Helmet>
        <title> National Consultant </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Мэдээ, мэдээлэл
          </Typography>
          <div style={{display: 'flex'}}>
            <Select name='language' 
                defaultValue={language} 
                onChange={(e) => setLanguage(e.target.value)}
                sx={{height: 40, mr:2, alignSelf: 'center'}}
            >
              <MenuItem value='mn'><em>MN</em></MenuItem>
              <MenuItem value='en'><em>EN</em></MenuItem>
              <MenuItem value='cn'><em>CN</em></MenuItem>
            </Select>
            <Button variant="contained" onClick={() => navigate(`/dashboard/blog/${language}/add`)} startIcon={<Iconify icon="eva:plus-fill" />}>
              Нэмэх
            </Button>
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Typography id="modal-modal-title" variant="h6" m={3} component="h2">
                  Шинэ мэдээ оруулах
                </Typography>
                <TextField
                  name="image"
                  label="Зургын URL"
                  style={{ marginBottom: 20 }}
                  value={imageUrl}
                  onChange={e => setImageUrl(e.target.value)}
                />
                <TextField
                  name="title"
                  label="Гарчиг"
                  style={{ marginBottom: 20 }}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
                <TextField
                  name="desc"
                  label="тайлбар"
                  style={{ marginBottom: 20 }}
                  multiline
                  rows={3}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
                <Select
                  name="org"
                  label="Холбоотой байгууллага"
                  style={{ marginBottom: 20 }}
                  value={org}
                  onChange={e => setOrg(e.target.value)}
                >
                  {company.map(data => (
                    <MenuItem key={data.id} value={data.id}>{data.name_mn}</MenuItem>
                  ))}
                </Select>
                <Button onClick={event => handleAdd(event)} variant="contained">Нэмэх</Button>
              </div>
            </Box>
          </Modal>
        </Stack>

        {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={POSTS} />
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack> */}

        {/* <Grid container spacing={3}>
          {POSTS.map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          ))}
        </Grid> */}
        {loading && (<Box sx={{ display: 'flex' }}><CircularProgress /></Box>)}
        <AppNewsUpdate
          refetch={fetchNews}
          language={language}
          title="News"
          list={posts}
        />
      </Container >
    </>
  );
}
