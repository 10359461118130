import jwt from 'jwt-decode';

const token = localStorage.getItem('token') ? jwt(localStorage.getItem('token')) : '';

const account = {
  displayName: token.username || 'Хэрэглэгч',
  email: 'admin@gmail.com',
  photoURL: '/assets/images/avatars/avatar_10.jpg',
};

export default account;