import { useState, useEffect } from 'react';
import { Button, Container, Stack, Typography, Box, TextField, MenuItem } from '@mui/material';
import { getCompany, getNewsDetail, postNews, putNews, uploadImage } from '../../api/main';
import { useSnackbar } from 'notistack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';

const AddNewsPage = () => {

  const { id, lang } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (id) {
      getNewsDetail(id, lang).then(res => {
        setData(res.data);
      })
    }

    getCompany(lang).then(res => {
      setCompany(res.data);
    });
    
  }, [id, lang]);

  const handleRequest = async () => {
    setLoading(true);
    var isSuccess = false;
    if (id) {
      await putNews(id, lang, data).then(res => {
        if (res.success)
          isSuccess = true
      }).finally(() => setLoading(false));
    } else {
      await postNews(lang, data).then(res => {
        if (res.success)
          isSuccess = true
      }).finally(() => setLoading(false));
    }

    if (isSuccess) {
      enqueueSnackbar('Амжилттай', { variant: 'success' });
      navigate('/dashboard/blog', { state: { lang: lang } })
    }
    else
      enqueueSnackbar('Алдаа гарлаа', { variant: 'error' });
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    uploadImage(formData).then(res => {
      setIsUpload(true);
      setData({ ...data, imageUrl: res.path })
    }).catch(error => {
      console.error(error);
    });
  };

  if (loading)
    return <></>

  return (
    <>
      <Container>
        <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
          <Box >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <Typography id="modal-modal-title" variant="h6" m={3} component="h2">
                {id ? "Мэдээ засах" : 'Шинэ мэдээ оруулах'} ({lang.toUpperCase()})
              </Typography>
              <Button
                variant="contained"
                component="label"
                style={{ marginBottom: 20 }}
                disabled={isUpload}
              >
                {isUpload ? 'Зураг хуулагдлаа' : 'Зургаа оруулах'}
                <input type='file' onChange={handleFileUpload} hidden />
              </Button>
              <TextField
                name="topic"
                label="Гарчиг"
                style={{ marginBottom: 20 }}
                value={data?.topic || ""}
                onChange={handleOnChange}
              />
              <TextField
                name="description"
                label="Тайлбар"
                style={{ marginBottom: 20 }}
                multiline
                rows={3}
                value={data?.description || ""}
                onChange={handleOnChange}
              />
              <TextField
                name="organization_id"
                label="Холбоотой байгууллага"
                style={{ marginBottom: 20 }}
                value={data?.organization_id || ""}
                onChange={handleOnChange}
                select
              >
                {company.map(data => (
                  <MenuItem key={data.id} value={data.id}><em>{data.name}</em></MenuItem>
                ))}
              </TextField>
              <ReactQuill
                style={{ marginBottom: 20 }}
                theme="snow"
                name="content"
                value={data?.content || ""}
                onChange={(val) => setData({ ...data, content: val })} />

              <Button onClick={handleRequest} variant="contained">{id ? 'Засах' : 'Нэмэх'}</Button>
            </div>
          </Box>
        </Stack>
      </Container>
    </>
  );
}

export default AddNewsPage;