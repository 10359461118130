import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Card, Button, Stack, Select, MenuItem } from '@mui/material';
import Iconify from '../components/iconify';
import CustomTable from 'src/components/CustomTable';
import { getConsultant, deleteConsultant } from 'src/api/main';

const columns = [
  {
    field: 'display_name',
    headerName: 'Нэр',
    width: 100,
  },
  {
    field: 'last_name',
    headerName: 'Овог',
    width: 10,
  },
  {
    field: 'first_name',
    headerName: 'Нэр',
    width: 100,
  },
  {
    field: 'title',
    headerName: 'Гарчиг',
    width: 150,
  },
  {
    field: 'image',
    headerName: 'Зураг',
    width: 100,
  },
  {
    field: 'edit',
    headerName: 'Засах',
    width: 50,
    align: 'left'
  },
  {
    field: 'delete',
    headerName: 'Устгах',
    width: 50,
  },
];

const ProductsPage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [language, setLanguage] = useState('mn');

  useEffect(() => {
    getConsultant(language).then(res => {
      setData(res.data);
    })
  }, [language]);

  const edit = id => {
    navigate(`/dashboard/consultant/detail/${id}`);
  }

  return (
    <>
      <Helmet>
        <title> National Consultant </title>
      </Helmet>

      <Container>
        <Stack direction='row' alignItems='center' justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 5 }}>Зөвлөхүүд</Typography>
          <div style={{ display: 'flex' }}>
            <Select name='language'
              defaultValue={language}
              onChange={(e) => setLanguage(e.target.value)}
              sx={{ height: 40, mr: 2, alignSelf: 'center' }}
            >
              <MenuItem value='mn'><em>MN</em></MenuItem>
              <MenuItem value='en'><em>EN</em></MenuItem>
              <MenuItem value='cn'><em>CN</em></MenuItem>
            </Select>
            <Button variant="contained" onClick={() => navigate(`/dashboard/consultant/add/${language}`)} startIcon={<Iconify icon="eva:plus-fill" />}>
              Нэмэх
            </Button>
          </div>
        </Stack>
      </Container>
      <Card>
        <CustomTable rows={data} edit={edit} remove={deleteConsultant} columns={columns} />
      </Card>
    </>
  );
}

export default ProductsPage;