import axios from "../utils/axios";

const apiUrl = 'https://nconsult.mn/api';
// const apiUrl = 'http://localhost:8000/api'

export const signin = async (user) => {
  const { data } = await axios.post(`${apiUrl}/signin`, user);
  return data;
}

export const fetchUserList = async () => {
  const { data } = await axios.get(`${apiUrl}/user`);
  return data.data;
}

export const fetchCompanyList = async () => {
  const { data } = await axios.get(`${apiUrl}/organization`);
  return data.data;
}

export const postUser = async user => {
  const { data } = await axios.post(`${apiUrl}/user`, user);
  return data;
}

export const deleteUser = async id => {
  const { data } = await axios.delete(`${apiUrl}/user/${id}`);
  return data;
}

export const getNews = async (lang) => {
  const { data } = await axios.get(`${apiUrl}/news/${lang}`);
  return data;
}

export const getNewsDetail = async (id) => {
  const { data } = await axios.get(`${apiUrl}/news/detail/${id}`);
  return data;
}

export const uploadImage = async file => {
  const { data } = await axios.post(`${apiUrl}/upload`, file);
  return data;
}

export const postNews = async (lang, body) => {
  const { data } = await axios.post(`${apiUrl}/news/${lang}`, body);
  return data;
}

export const putNews = async (id, lang, body) => {
  const { data } = await axios.put(`${apiUrl}/news/detail/${id}`, body);
  return data;
}

export const deleteNews = async (id) => {
  const { data } = await axios.delete(`${apiUrl}/news/detail/${id}`);
  return data;
}

export const getCompany = async lang => {
  const { data } = await axios.get(`${apiUrl}/company/${lang}`);
  return data;
}

export const getCompanyDetail = async id => {
  const { data } = await axios.get(`${apiUrl}/company-detail/${id}`);
  return data;
}

export const putCompany = async (id, payload) => {
  const { data } = await axios.put(`${apiUrl}/company-detail/${id}`, payload);
  return data;
}

export const addCompany = async (payload, lang) => {
  const { data } = await axios.post(`${apiUrl}/company/${lang}`, payload);
  return data;
}

export const deleteCompany = async id => {
  const { data } = await axios.delete(`${apiUrl}/company-detail/${id}`);
  return data;
}

export const getConsultant = async lang => {
  const { data } = await axios.get(`${apiUrl}/consultant/${lang}`);
  return data;
}

export const addConsultant = async (lang, payload) => {
  const { data } = await axios.post(`${apiUrl}/consultant/${lang}`, payload);
  return data;
}

export const getConsultantDetail = async id => {
  const { data } = await axios.get(`${apiUrl}/consultant-detail/${id}`);
  return data;
}

export const putConsultant = async (id, payload) => {
  const { data } = await axios.put(`${apiUrl}/consultant-detail/${id}`, payload);
  return data;
}

export const deleteConsultant = async id => {
  const { data } = await axios.delete(`${apiUrl}/consultant-detail/${id}`);
  return data;
}