import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmptyContent from './EmptyContent';

const CustomTable = ({ loading, rows, columns, edit, remove, ...props }) => {
  const theme = useTheme();

  const handleRemove = id => {
    remove(id).then(res => {
      if (res.success) {
        alert('Амжилттай');
      }
    });
  }
  
  return (
    <Paper sx={{ width: '100%', overflowX: 'auto' }}>
      <Table size="small"
        sx={{
          minWidth: `${columns.length !== 0 ? columns.reduce((a, b) => a + b.width, 0) + 'px' : '100%'}`,
        }}>
        <TableHead>
          {columns.map((c, idx) => {
            return <TableCell key={idx} align='left' variant="head">{c.headerName}</TableCell>;
          })}
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Box m={2} display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          )}
          {!loading && rows && rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <EmptyContent title="Хоосон байна" description="Жагсаалт хоосон байна" />
              </TableCell>
            </TableRow>
          )}
          {rows &&
            rows.length > 0 &&
            rows.map((r, idx) => {
              return (
                <TableRow key={idx} component={Card} >
                  {columns.map((c, idx) => {
                    return (
                      <TableCell key={idx} variant="body" width={c.width} align={c.align}
                        sx={{
                          textAlign: `${!r[c.field] && 'center'}`,
                          maxWidth: c.width,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}>
                        {c.field === 'delete' 
                        ? <Button variant='text' onClick={() => handleRemove(r.id)}>Устгах</Button> 
                        : c.field === 'edit' ? <Button onClick={() => edit(r.id)}>Засах</Button> 
                        : c.renderCell ? (<c.renderCell row={r} value={r[c.field] || '!'} />) 
                        : (r[c.field] || '-')}
                        {/* {c.renderCell ? (<c.renderCell row={r} value={r[c.field] || '!'} />) : (r[c.field] || '-')} */}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default CustomTable;
