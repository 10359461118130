import { useState, useEffect } from "react";
import { Container, Stack, Box, Typography, TextField, Button, CircularProgress, MenuItem, Select, OutlinedInput } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { addCompany, uploadImage, getConsultant, getCompanyDetail, putCompany } from "src/api/main";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddCompanyPage = () => {
  const navigate = useNavigate();
  const { id, lang } = useParams();

  const [data, setData] = useState({});
  const [consultant, setConsultant] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (id) {
      getCompanyDetail(id).then(res => {
        setData(res.data[0]);
        const ids = res.data[0].consultants.map(c => c.id);
        setSelectedConsultant(ids);
      })
    }

    getConsultant(lang).then(res => {
      setConsultant(res.data);
    })
  }, []);

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const handleSelect = event => {
    const { value } = event.target;
    const lastConsultant = typeof (value) === 'string' ? value.split(',') : value;
    console.log(lastConsultant)
    setSelectedConsultant(typeof (value) === 'string' ? value.split(',') : value);
    setData({ ...data, 'consultant': lastConsultant })
  };

  const handleAdd = () => {
    setLoading(true);
    if (id) {
      putCompany(id, data).then(res => {
        setLoading(false);
        if (res.success) {
          console.log('Амжилттай');
        }
        navigate('/dashboard/company');
      });
    } else {
      addCompany(data, lang).then(res => {
        setLoading(false);
        if (res.success) {
          console.log('Амжилттай');
        }
        navigate('/dashboard/company');
      });
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    uploadImage(formData).then(res => {
      setData({ ...data, [event.target.name]: res.path })
      if (event.target.name === 'logo_url') {
        setLogo(res.path);
      } else {
        setImage(res.path);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  return (
    <Container>
      <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
        <Box>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            <Typography id='model-modal-title' variant="h6" m={3} component="h2">
              Байгууллага нэмэх
            </Typography>
            {logo ? <img src={logo} width={200} /> : (
              <Button
                variant="contained"
                component="label"
                style={{ marginBottom: 20 }}
              >
                Лого оруулах
                <input type='file' name="logo_url" onChange={handleFileUpload} hidden required />
              </Button>
            )}
            <TextField
              name="name"
              label="Байгууллагын нэр"
              value={data?.name || ""}
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              required
            />
            {/* <TextField
              name="description"
              label="Тайлбар"
              multiline
              rows={5}
              value={data?.description || ""}
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              required
            /> */}
            <ReactQuill
              style={{ marginBottom: 20 }}
              theme="snow"
              name="description"
              value={data?.description || ""}
              onChange={value => setData({ ...data, description: value })} />

            <Typography mb={1}>Зөвлөх сонгох</Typography>
            <Select
              id="consultant"
              multiple
              value={selectedConsultant}
              onChange={handleSelect}
              input={<OutlinedInput label="Name" />}
              style={{ marginBottom: 20 }}
              MenuProps={MenuProps}
            >
              {consultant.map(data => (
                <MenuItem
                  key={data.id}
                  value={data.id}
                >
                  {data.display_name}
                </MenuItem>
              ))}
            </Select>
            {image ? <img src={image} width={300} /> : (
              <Button
                variant="contained"
                component="label"
                style={{ marginBottom: 20 }}
              >
                Зураг оруулах
                <input type='file' name='image_url' onChange={handleFileUpload} hidden />
              </Button>
            )}
            <Button onClick={handleAdd} variant="contained">{id ? 'Засах' : 'Нэмэх'} </Button>
            {loading && <CircularProgress color="inherit" />}
          </div>
        </Box>
      </Stack>
    </Container>
  );
}

export default AddCompanyPage;