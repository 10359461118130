import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
};

export default function RHFSelect({ name, children, disabled = false, defaultValue, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => {
                return (
                    <TextField
                        select
                        disabled={disabled}
                        {...field}
                        name={name}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        error={!!error}
                        helperText={error?.message}
                        {...other}
                    >
                        {children}
                    </TextField>
                );
            }}
        />
    );
}
