import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { putNews, deleteNews } from '../../../api/main';
import { fToNow } from '../../../utils/formatTime';
import Scrollbar from '../../../components/scrollbar';
import { useSnackbar } from 'notistack';

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ topic, language, subheader, refetch, list, ...other }) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const toggleDialog = (id) => {
    setSelected(id)
    setOpen(!open);
  };
  
  return (
    <Card {...other}>
      <DeleteDialog id={selected} open={open} toggleDialog={toggleDialog} refetch={refetch}/>
      <CardHeader title={topic} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 3 }}>
          {list.map((news) => (
            <NewsItem key={news.id} news={news} lang={language} toggleDialog={toggleDialog}/>
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button size="small" color="inherit" onClick={() => navigate('/dashboard/blog')} endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
          View all
        </Button>
      </Box> */}
    </Card>
  );
}

const DeleteDialog = ({id, open, toggleDialog, refetch}) =>  {
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    var isSuccess = false;
    await deleteNews(id).then(res => {
      if (res.success) {
        isSuccess = true
        refetch();
      }
    }).finally(() => toggleDialog());

    if (isSuccess){
      enqueueSnackbar('Амжилттай', { variant: 'success' });
    }
    else
      enqueueSnackbar('Алдаа гарлаа', { variant: 'error' });
  }

  return(
    <Dialog
          open={open}
          onClose={toggleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Мэдээлэл устгах"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Энэхүү мэдээллийг устгахдаа итгэлтэй байна уу?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog}>Хаах</Button>
            <Button color='error' onClick={handleDelete} autoFocus>
              Зөвшөөрөх
            </Button>
          </DialogActions>
    </Dialog>
  )
}

NewsItem.propTypes = {
  lang: PropTypes.string,
  news: PropTypes.shape({
    description: PropTypes.string,
    imageurl: PropTypes.string,
    postedat: PropTypes.string,
    topic: PropTypes.string,
  }),
};

function NewsItem({ lang, news, toggleDialog }) {
  const { id, imageurl, topic, description, postedat, monitor } = news;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = id => {
    setLoading(true);
    putNews(id).then(res => {
      if (res.success) {
        enqueueSnackbar('Амжилттай', { variant: 'success' });
      } else {
        enqueueSnackbar('Алдаа гарлаа', { variant: 'error' });
      }
      setLoading(false);
    })
  }

  return (
    <div>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box component="img" alt={topic} src={imageurl ? imageurl : "https://semantic-ui.com/images/wireframe/image.png"} sx={{ width: 120, height: 80, borderRadius: 1.5, flexShrink: 0 }} />

        <Box sx={{ minWidth: 240, flexGrow: 1 }} onClick={() => navigate(`/dashboard/blog/${lang}/details/${id}`, { state: { }})}>
          <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
            {topic}
          </Link>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {description}
          </Typography>
        </Box>

        <Typography variant="caption" sx={{ flexShrink: 0, color: 'text.secondary' }}>
          {fToNow(postedat)}
        </Typography>

        <Button variant="outlined" color="error" sx={{minWidth: 80}} onClick={() => toggleDialog(id)}>
          Устгах
        </Button>
      </Stack>
    </div>
  );
}
