import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

const USERS = [
  'Б.Батзориг',
  'О.Цэнгэлбаатар',
  'Я.Батжаргал',
  'Б.Тунгалагнаран',
  'Г.Цэцгээ',
  'М.Энхжаргал',
  'Г.Золзаяа'
]

const users = [...Array(7)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.png`,
  name: USERS[index],
  company: faker.company.name(), 
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  role: sample([
    'Director',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer',
  ]),
}));

export default users;
