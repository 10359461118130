import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow, MenuItem, TableBody, TableCell, Container, Typography, TextField, IconButton, TableContainer, TablePagination, Modal, Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import CustomTable from "../components/CustomTable";
import Iconify from '../components/iconify';
import USERLIST from '../_mock/user';
import { fetchUserList, postUser, deleteUser } from '../api/main';
import { useSnackbar } from 'notistack';

const columns = [
  {
      field: 'username',
      headerName: 'Нэр',
      width: 200,
      align: 'left'
  },
  {
      field: 'email',
      headerName: 'Цахим шуудан',
      width: 300,
  },
  {
      field: 'phone',
      headerName: 'Утасны дугаар',
      align: 'center',
      width: 100,
  },
  {
      field: 'role',
      headerName: 'Эрх',
      align: 'center',
      renderCell: (params) => {return params.value === 1 ? 'Админ' : 'Хэрэглэгч'},
      width: 140,
  },
  {
      field: 'address',
      headerName: 'Хаяг',
      align: 'center',
      width: 200,
  },
  {
      field: 'title',
      headerName: 'Албан тушаал',
      align: 'center',
      width: 100,
  },
  {
      field: 'company',
      headerName: 'Байгууллага',
      align: 'center',
      width: 100,
  },
  {
    field: 'delete',
    headerName: 'Устгах',
    align: 'center',
    width: 50,
},
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UserPage = () => {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userOpen, setUserOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({
    username: '',
    pass: '',
    email: '',
    phone: '',
    role: 0,
    address: '',
    title: '',
    company: ''
  });

  const handleOpen = () => setUserOpen(true);
  const handleClose = () => setUserOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleAdd = () => {
    postUser(user).then(res => {
      if (res.success) {
        enqueueSnackbar('Амжилттай', { variant: 'success' });
        handleClose();
      } else {
        enqueueSnackbar('Алдаа гарлаа', { variant: 'error' });
      }
    });
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const { data, isLoading } = useQuery({ queryFn: fetchUserList, queryKey: ['user-list'] });

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title>Хэрэглэгчдийн жагсаалт</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Админ хэрэглэгчид
          </Typography>
          <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
            Нэмэх
          </Button>
        </Stack>
        <Modal
          open={userOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">Хэрэглэгч үүсгэх</Typography>
              <TextField
                name="username"
                label="Нэр"
                style={{ marginBottom: 20 }}
                value={user.username}
                onChange={e => setUser({ ...user, username: e.target.value } )}
              />
              <TextField
                name="password"
                label="Нууц үг"
                type='password'
                style={{ marginBottom: 20 }}
                value={user.pass}
                onChange={e => setUser({ ...user, pass: e.target.value } )}
              />
              <TextField
                name="email"
                label="Майл"
                style={{ marginBottom: 20 }}
                value={user.email}
                onChange={e => setUser({ ...user, email: e.target.value } )}
              />
              <TextField
                name="phone"
                label="Дугаар"
                style={{ marginBottom: 20 }}
                value={user.phone}
                onChange={e => setUser({ ...user, phone: e.target.value } )}
              />
              <TextField
                name="company"
                label="Ажлын газар"
                style={{ marginBottom: 20 }}
                value={user.company}
                onChange={e => setUser({ ...user, company: e.target.value } )}
              />
               <TextField
                name="position"
                label="Ажлын чиглэл"
                style={{ marginBottom: 20 }}
                value={user.title}
                onChange={e => setUser({ ...user, title: e.target.value } )}
              />
              <Button onClick={() => handleAdd()} variant="contained">Нэмэх</Button>
            </div>
          </Box>
        </Modal>

        <Card>
          <CustomTable rows={data} columns={columns} remove={deleteUser} loading={isLoading} />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}

export default UserPage;